<template>
    <MyHeader/>
    <MyAbout/>
    <MySkills/>
    <MyVision/>
    <MyWorksabc/>
    <MyWorkshopc/>
    <MyWorkslife/>
    <MyWorksdialr/>
    <MyFooter/>
    <LoadingScreen/>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue'
import MyAbout from '@/components/MyAbout.vue';
import MySkills from '@/components/MySkills.vue';
import MyVision from '@/components/MyVision.vue';
import MyWorksabc from '@/components/MyWorks-abc.vue';
import MyWorkshopc from '@/components/MyWorks-hopc.vue';
import MyWorkslife from '@/components/MyWorks-life.vue';
import MyWorksdialr from '@/components/MyWorks-dialr.vue';
import MyFooter from '@/components/MyFooter.vue';
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
  name: 'HomePage',
  components: {
    MyHeader,
    MyAbout,
    MySkills,
    MyVision,
    MyWorksabc,
    MyWorkshopc,
    MyWorkslife,
    MyWorksdialr,
    MyFooter,
    LoadingScreen
}
}
</script>

<style lang="scss" scoped>
</style>