// MySkills.vue
<template>
  <section class="skills">
    <div class="section__skills--container">
      <div class="section__skills--heading en">Skills</div>
      <ul class="section__skills--item-box en">
        <li class="section__skills--item">Brand Identities</li>
        <li class="section__skills--item">Branded Content</li>
        <li class="section__skills--item">Development</li>
        <li class="section__skills--item">Design Strategy</li>
        <li class="section__skills--item">Graphicdesign</li>
        <li class="section__skills--item">Photography</li>
        <li class="section__skills--item">Project Direction</li>
        <li class="section__skills--item">Video Production</li>
        <li class="section__skills--item">Web/SNS Marketing</li>
        <li class="section__skills--item">Webdesign</li>
        <li class="section__skills--item">UX Design</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MySkills'
}
</script>
<style lang="scss" scoped>
.section__skills {
  &--container {
    display: grid;
    gap: 10px;
  }
  &--heading {
    font-weight: var(--global--weight-bold);
  }
  &--item {
    font-size: 0.9rem;
    line-height: 1.5em;
  }
}
</style>