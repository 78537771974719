import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// スタイルシートのインポート
// import 'ress';                     // npm経由でインストールしたress.cssをインポート
import '@/assets/scss/main.scss';  // コンポーネントに取り込んでいないscssファイル
import '@/assets/scss/animation.scss';  // コンポーネントに取り込んでいないscssファイル
import '@/assets/scss/works.scss';  // コンポーネントに取り込んでいないscssファイル

// Vue 2の `new Vue({...})` の代わりに `createApp` を使用します。
const app = createApp(App);

// Vueアプリケーションにルーターを適用
app.use(router);

// アプリケーションをマウント
app.mount('#app');