// MyHeader.vue
<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__contact--container">
        <div class="footer__contact--description">
          <div class="footer__contact--heading en">Contact Here</div>
          <a href="mailto:bara@drive-d.com,koichiro@drive-d.com?subject=drive%20inc.%E3%81%B8%E3%81%AE%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B" class="footer__contact--action">✉️</a>
          <div class="footer__contact--to en">to SAKAKIBARA & YAMANA</div>
        </div>
        <div class="section__info--container">
          <div class="section__info--heading en">Company Info</div>
          <div class="section__info--text ja">
            株式会社ドライブデザイン<br>
            代表取締役社長：榊原 優生<br>
            所在地：東京都渋谷区渋谷2-19-15 宮益坂ビルディング609号<br>
            設立：2023年12月22日
          </div>
        </div>
      </div>
    </div>
    <div class="footer__copyrights en">© drive design inc.</div>
  </footer>
</template>

<script>
export default {
  name: 'MyHeader'
}
</script>
<style lang="scss" scoped>
footer {
  width: calc(100% - 50px);
  height: calc(100vh - 50px);
  background-color: var(--global--color-secondary);
  padding-top: 25px;
  padding-bottom: 25px;
}
.footer__contact--container, .footer__container {
    height: 100%;
    position: relative;
}

.footer__contact--description {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.footer__contact--heading {
    font-weight: var(--global--weight-semibold);
    font-size: 3rem;
    height: 3rem;
    line-height: 1em;
}

.footer__contact--action {
    font-size: 2.5rem;
    height: 2.5em;
    width: 2.5em;
    line-height: 2.5em;
    text-align: center;
    background-color: #fff;
    border-radius: 2em;
}

.footer__contact--to {
    font-weight: var(--global--weight-semibold);
}
.section__info--container {
    position: absolute;
    bottom: 0;
    display: grid;
    gap: 5px;
}

.section__info--heading {
    font-weight: var(--global--weight-bold);
}
.section__info--text {
    font-size: 0.8em;
    line-height: 1.5em;
}
.footer__copyrights {
    position: fixed;
    bottom: 5em;
    right: calc(-4em + 9px);
    transform: rotate(-90deg);
    font-size: 12px;
}
</style>