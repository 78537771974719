// MyWorks-life.vue
<template>
  <section class="works works-life">
    <div class="section__works--container">
      <picture :class="{ 'is-visible': isDetailVisible }">
        <img src="../assets/images/works-003.png" alt="" width="80%">
      </picture>
      <div class="section__works--description">
        <div class="section__works--heading en-heading">LIFENET</div>
        <div class="section__works--box">
          <div class="section__works--text en">Photography for SNS</div>
          <div :class="{ 'is-invisible': isDetailInvisible }" class="section__works--link en">
            <router-link to="/" @click.prevent="handleClick">More</router-link>
          </div>
          <div :class="{ 'is-visible': isDetailVisible }" class="section__works--link en invisible">
            <router-link to="/" @click.prevent="hiddenDetail">Close</router-link>
          </div>
        </div>
      </div>
      <div :class="{ 'is-visible': isDetailVisible }" class="ja section__works--detail invisible">
        <strong>「チャレンジする瞬間のスチール撮影」</strong><br><br>
        Twitterプロモーション用に、コロナ禍で困難に立ち向かう人たちの生き生きとした瞬間をスチール撮影しました。実際のお客様が出演し、彼らの日常やチャレンジの一部始終を切り取りました。<br><br>
        撮影中は出演者とコミュニケーションを密に取り、リラックスした環境を整えることで、自然な笑顔や表情を引き出せるよう心掛けました。その結果、真実味のある、心を打つ写真が完成しました。
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MyWorkslife',
  data() {
    return {
      isDetailVisible: false,
      isDetailInvisible: false
    };
  },
  methods: {
    handleClick(event) {
      this.scrollToSection(event);
      this.showDetail();
    },
    scrollToSection(event) {
      let section = event.target.closest('.works');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    showDetail() {
      this.isDetailVisible = true;
      this.isDetailInvisible = true;
    },
    hiddenDetail() {
      this.isDetailVisible = false;
      this.isDetailInvisible = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.works-life {
  background-image: url(../assets/images/works-003-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.section__works--detail {
  max-height: 0px;
  color: var(--global--color-base);
  margin-top: 10px;
  filter: drop-shadow(0px 0px 10px #262626);
  &.is-visible {
    max-height: calc(100vh - 250px);
    overflow: scroll;
    // background-color: #26262680;
    border-radius: 5px;
  }
}
.section__works--link {
  &.invisible, &.is-invisible {
    transition: none;
  }
}
picture {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  position: absolute;
  &.is-visible {
    transform: translateX(-50%);
    opacity: 0;
  }
}
</style>