// MyAbout.vue
<template>
  <section class="about">
    <div :class="{ 'is-visiblegap': isContentVisible }" class="section__about--container">
      <div class="section__about--wrapper first-content">
        <div :class="{ 'is-visibleitem': isContentVisible }" class="section__about--item">
          <div class="section__about--position en">Co-director / Designer / Founder</div>
          <div class="section__about--name en-heading">SAKAKIBARA<br>YUKI</div>
        </div>
        <div :class="{ 'is-visible': isContentVisible }" class="invisible section__about--image">
          <img src="../assets/images/img-sy.png" alt="SAKAKIBARA YUKI" class="" width="100%">
        </div>
        <div :class="{ 'is-visible': isContentVisible }" class="ja section__about--description invisible">
          クリエイティブディレクター／デザイナー<br>
          1995年千葉県出身。千葉工業大学大学院デザイン科学専攻修了。2023年にDRIVE DESIGN Inc.を設立。主に企業のブランディングとプロモーションを手がける。デザイナーの観点からWEB、グラフィック、映像など媒体を超えるアウトプット、エンジニアリングやマーケティング、エディティングなど様々なアプローチで課題解決と価値創造を行う。
        </div>
      </div>
      <div class="section__about--wrapper second-content">
        <div :class="{ 'is-visibleitem': isContentVisible }" class="section__about--item">
          <div class="section__about--position en">Co-director / Producer</div>
          <div class="section__about--name en-heading">YAMANA<br>KOUICHIRO</div>
        </div>
        <div :class="{ 'is-visible': isContentVisible }" class="invisible section__about--image">
          <img src="../assets/images/img-yk.png" alt="YAMANA KOUICHIRO" class="" width="100%">
        </div>
        <div :class="{ 'is-visible': isContentVisible }" class="ja section__about--description invisible">
          プロジェクトディレクター／プロデューサー<br>
          1995年栃木県出身。千葉工業大学デザイン工学部卒業。DRIVE DESIGN Inc.設立前より同プロジェクトに参加。
        </div>
      </div>
    </div>
    <div :class="{ 'is-invisible': isContentInvisible }" class="section__about--link en">
      <router-link to="/" @click.prevent="showContent">About us</router-link>
    </div>
    <div :class="{ 'is-visible': isContentVisible }" class="section__about--link en invisible">
      <router-link to="/" @click.prevent="hiddenContent">Close</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MyAbout',
  data() {
    return {
      isContentVisible: false,
      isContentInvisible: false
    };
  },
  methods: {
    showContent() {
      this.isContentVisible = true;
      this.isContentInvisible = true;
    },
    hiddenContent() {
      this.isContentVisible = false;
      this.isContentInvisible = false;
    }
  }
}
</script>
<style lang="scss" scoped>
  .about {
    position: relative;
    margin-top: 80px;
  }
  .section__about {
    &--container {
      display: grid;
      width: 100%;
      margin: 0 auto 60px;
    }
    &--wrapper {
      &.first-content {
        margin-bottom: 80px;
      }
      &.second-content {
        margin-bottom: 40px;
      }
    }
    &--item {
      width: 230px;
      margin: auto;
      display: grid;
      gap: 5px;
      padding: 23px 0 0 30px;
      position: relative;
      z-index: 5;
      &::before {
        content: "";
        width: 51px;
        aspect-ratio: 2.17;
        position: absolute;
        top: 0;
        left: 0;
        border-top: 1px solid var(--global--color-primary);
        border-left: 1px solid var(--global--color-primary);
      }
    }
    &--image {
      max-width: calc(100% - 100px);
      margin-left: auto;
      margin-top: -45px;
    }
    &--description {
      margin-top: 25px;
    }
    &--position {
      font-size: 0.9rem;
      font-weight: var(--global--weight-semibold);
    }
    &--name {
      font-size: 1.5rem;
      font-weight: var(--global--weight-bold);
      line-height: var(--global--line-height-heading);
      letter-spacing: 0.25em;
    }
    &--link {
      position: absolute;
      right: 0;
      padding: 0px 25px 0px 5px;
      background-color: var(--global--color-secondary);
      &.en {
        color: var(--global--color-primary);
        font-weight: var(--global--weight-semibold);
      }
    }

    // animation
    &--link {
      &.invisible, &.is-invisible {
        transition: none;
      }
    }
    &--wrapper {
      &.first-content {
        transition: margin-bottom 1s ease-in-out;
      }
    }
    &--item {
      transition: width 1s ease-in-out;
    }
    &--image, &--description {
      max-height: 0;
      min-height: 0;
      &.is-visible {
        max-height: 550px;
      }
    }
  }
  .is-visiblegap {
    .section__about--wrapper.first-content {
      margin-bottom: 100px;
    }
  }
  .is-visibleitem.section__about--item {
    width: calc(100% - 30px);
  }
</style>