// MyWorks-dialr.vue
<template>
  <section class="works works-dialr">
    <div class="section__works--container">
      <picture :class="{ 'is-visible': isDetailVisible }">
        <img src="../assets/images/works-004.png" alt="" width="100%">
      </picture>
      <div class="section__works--description">
        <div class="section__works--heading en-heading">DIAL SERVICE RECRUIT</div>
        <div class="section__works--box">
          <div class="section__works--text en">Client Direction</div>
          <div :class="{ 'is-invisible': isDetailInvisible }" class="section__works--link en">
            <router-link to="/" @click.prevent="handleClick">More</router-link>
          </div>
          <div :class="{ 'is-visible': isDetailVisible }" class="section__works--link en invisible">
            <router-link to="/" @click.prevent="hiddenDetail">Close</router-link>
          </div>
        </div>
      </div>
      <div :class="{ 'is-visible': isDetailVisible }" class="ja section__works--detail invisible">
        <strong>「協調と効率の中で生まれるリクルートサイト」</strong><br><br>
        リクルートサイト作成において、クライアントの一員として参加しました。社内のリクルートチームの指導のもと、ベンダーの管理も行いながら、サイトの構成を進めました。私たちは、チームが求める情報の表現と、クライアントのブランド管理の両方を考慮しながら、サイトを設計しました。<br><br>
        短期間でのプロジェクト実行には、単に工程を短縮するのではなく、効率的なフロー構築を通じてスケジュールを最適化しました。キービジュアルの撮影には、社内のリソースを活用することで、コスト削減にも大きく貢献しました。
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MyWorksdialr',
  data() {
    return {
      isDetailVisible: false,
      isDetailInvisible: false
    };
  },
  methods: {
    handleClick(event) {
      this.scrollToSection(event);
      this.showDetail();
    },
    scrollToSection(event) {
      let section = event.target.closest('.works');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    showDetail() {
      this.isDetailVisible = true;
      this.isDetailInvisible = true;
    },
    hiddenDetail() {
      this.isDetailVisible = false;
      this.isDetailInvisible = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.works-dialr {
  background-color: #6AC3C6;
  background-image: url(../assets/images/works-004-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.section__works--detail {
  max-height: 0px;
  color: var(--global--color-base);
  margin-top: 10px;
  filter: drop-shadow(0px 0px 10px #262626);
  &.is-visible {
    max-height: calc(100vh - 250px);
    overflow: scroll;
    // background-color: #26262680;
    border-radius: 5px;
  }
}
.section__works--link {
  &.invisible, &.is-invisible {
    transition: none;
  }
}
picture {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  position: absolute;
  &.is-visible {
    transform: translateX(-50%);
    opacity: 0;
  }
}
</style>