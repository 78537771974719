import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import MyAbout from '@/components/MyAbout.vue'; // MyAbout コンポーネントをインポート

const routes = [
  { path: '/', component: Home },
  { path: '/aboutus', component: MyAbout } // '/aboutus' パスで MyAbout コンポーネントを表示
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
