// MyVision.vue
<template>
  <section class="vision">
    <div class="section__vision--container">
      <div class="section__vision--heading en">Vision</div>
      <div class="section__vision--text ja">
        DRIVE DESIGN Inc.は、デザインを単なるグラフィックやWEBの創作活動とは異なる、「設計」という枠組みで捉えています。多くの場合、クリエイティブ作品自体がデザインと見なされることがありますが、私たちにとってデザインとは、そのクリエイティブに方向性と目的を与え、計画と設計を行うプロセスそのものです。<br>
        課題の発見から解決策の設計に至るまで、デザインを通じて数多くのクリエイティブなアプローチを実行し、ビジネスに実質的な貢献をしていきます。<br>
        クリエイティブとデザインの力でビジネスを推進する、それがDRIVE DESIGN Inc.です。
      </div>
    </div>
  </section>
  <section class="vision">
    <div class="section__vision--container">
      <div class="section__vision--heading en">What DRIVE</div>
      <div class="section__vision--text ja">
        デザインでデザインを変えていく。<br>
        近頃、デザインの多様性とその簡潔さにより、日本におけるデザイン、アート、そしてクリエイティブという概念が、かつてないほど互いに重なり合っています。それでも、これらの概念は根本的に異なる意味を持ちます。デザインは外部からのデータや知識を取り入れ、プロセスを構築し、課題を解決する行為です。一方、アートは、個人や共同体の内側から湧き出る情感を形にすること。そして、クリエイティブは、これらの要素を組み合わせて新たな価値を創造することです。私たちは、各領域に対する深い理解と共感を大切にし、共に創り上げるプロセスを通じて、より多くの人々が「デザインをする」喜びと自信を育んでいくことで、社会全体がクリエイティブになると信じています。
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MyVision'
}
</script>
<style lang="scss" scoped>
.section__vision {
  &--container {
    display: grid;
    gap: 5px;
  }
  &--heading {
    font-weight: var(--global--weight-bold);
  }
}
</style>