// MyWorks-abc.vue
<template>
  <section class="works works-abc">
    <div class="section__works--container">
      <picture :class="{ 'is-visible': isDetailVisible }">
        <img src="../assets/images/works-001.png" alt="" width="100%">
      </picture>
      <div class="section__works--description">
        <div class="section__works--heading en-heading">Appearance Beauty Clinic</div>
        <div class="section__works--box">
          <div class="section__works--text en">Website Production</div>
          <div :class="{ 'is-invisible': isDetailInvisible }" class="section__works--link en">
            <router-link to="/" @click.prevent="handleClick">More</router-link>
          </div>
          <div :class="{ 'is-visible': isDetailVisible }" class="section__works--link en invisible">
            <router-link to="/" @click.prevent="hiddenDetail">Close</router-link>
          </div>
        </div>
      </div>
      <div :class="{ 'is-visible': isDetailVisible }" class="ja section__works--detail invisible">
        <strong>「美容クリニックの新しい顔」</strong><br><br>
        たくさんのサービスをわかりやすく整理して、ウェブサイトに訪れる人がすぐに必要な情報を見つけられるようにしました。クリニックの良さはそのままに、今の時代に合ったスタイルでサイトを作り上げました。<br><br>
        このウェブサイトでは、ブログを使って最新の美容情報も発信しています。プロジェクトは、構成、ウェブデザイン、サイト実装の全ての段階で、クリニックの良さを生かしながら、使いやすくて魅力的なサイトにしました。</div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MyWorksabc',
  data() {
    return {
      isDetailVisible: false,
      isDetailInvisible: false
    };
  },
  methods: {
    handleClick(event) {
      this.scrollToSection(event);
      this.showDetail();
    },
    scrollToSection(event) {
      let section = event.target.closest('.works');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    showDetail() {
      this.isDetailVisible = true;
      this.isDetailInvisible = true;
    },
    hiddenDetail() {
      this.isDetailVisible = false;
      this.isDetailInvisible = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.works-abc {
  background-color: #6AC3C6;
  background-image: url(../assets/images/works-001.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.section__works--detail {
  max-height: 0px;
  color: var(--global--color-base);
  margin-top: 10px;
  // filter: drop-shadow(0px 0px 10px #262626);
  &.is-visible {
    max-height: calc(100vh - 250px);
    overflow: scroll;
    // background-color: #26262680;
    border-radius: 5px;
  }
}
.section__works--link {
  &.invisible, &.is-invisible {
    transition: none;
  }
}
picture {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  position: absolute;
  &.is-visible {
    transform: translateX(-50%);
    opacity: 0;
  }
}
</style>