// MyWorks-hopc.vue
<template>
  <section class="works works-hopc">
    <div class="section__works--container">
      <picture :class="{ 'is-visible': isDetailVisible }">
        <img src="../assets/images/works-002.png" alt="" width="80%">
      </picture>
      <div class="section__works--description">
        <div class="section__works--heading en-heading">HANCE ONLINE PARTNER CLUB</div>
        <div class="section__works--box">
          <div class="section__works--text en">Membership site Production</div>
          <div :class="{ 'is-invisible': isDetailInvisible }" class="section__works--link en">
            <router-link to="/" @click.prevent="handleClick">More</router-link>
          </div>
          <div :class="{ 'is-visible': isDetailVisible }" class="section__works--link en invisible">
            <router-link to="/" @click.prevent="hiddenDetail">Close</router-link>
          </div>
        </div>
      </div>
      <div :class="{ 'is-visible': isDetailVisible }" class="ja section__works--detail invisible">
        <strong>「アーティストのためのメンバーシップサイト」</strong><br><br>
        人気ミュージックアーティスト専用のメンバーシップサイトを作成しました。このサイトでは、アーティストの魅力を際立たせるコンテンツを継続的に更新しやすいように工夫しました。会員はカード決済を通じてログインでき、サービスは自動で更新されます。<br><br>
        スマートフォンファーストのデザインで、作業時間を短縮し、コストの削減を実現。このプロジェクトでは、ウェブデザインの制作から、実際のサイト構築を行いました。</div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MyWorkshopc',
  data() {
    return {
      isDetailVisible: false,
      isDetailInvisible: false
    };
  },
  methods: {
    handleClick(event) {
      this.scrollToSection(event);
      this.showDetail();
    },
    scrollToSection(event) {
      let section = event.target.closest('.works');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    showDetail() {
      this.isDetailVisible = true;
      this.isDetailInvisible = true;
    },
    hiddenDetail() {
      this.isDetailVisible = false;
      this.isDetailInvisible = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.works-hopc {
  background-image: url(../assets/images/works-002-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.section__works--detail {
  max-height: 0px;
  color: var(--global--color-base);
  margin-top: 10px;
  filter: drop-shadow(0px 0px 10px #262626);
  &.is-visible {
    max-height: calc(100vh - 250px);
    overflow: scroll;
    // background-color: #26262680;
    border-radius: 5px;
  }
}
.section__works--link {
  &.invisible, &.is-invisible {
    transition: none;
  }
}
picture {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  position: absolute;
  &.is-visible {
    transform: translateX(-50%);
    opacity: 0;
  }
}
</style>